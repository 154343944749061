<template>
  <div class="wrap">
    <div class="hint">
      打通科技成果转化的“最后一公里”，南繁硅谷云首创了“成果转化”这一平台。平台运用大数据技术，实现线上的互动式对接，推进科技成果转化和企业科技公关。实现“智能匹配、精准推送、及时反馈、促进转化”是供需双方通过互联网交互方式进行最高的交流。
    </div>
    <div class="addressWrap">
      <div class="typeWrap addressItem">
        <el-row>
          <el-col :span="2">成果类型</el-col>
          <el-col :span="22">
            <span
              :class="item.isChecked ? 'isActive' : ''"
              @click="selectType(index, item.type)"
              v-for="(item, index) in typeList"
              :key="index"
              >{{ item.name }}</span
            >
          </el-col>
        </el-row>
      </div>
      <div class="budgetWrap addressItem">
        <el-row>
          <el-col :span="2">选择预算</el-col>
          <el-col :span="22">
            <span
              :class="item.isChecked ? 'isActive' : ''"
              @click="selectBudget(index, item.name)"
              v-for="(item, index) in budgetList"
              :key="index"
              >{{ item.name }}</span
            >
          </el-col>
        </el-row>
      </div>
      <div class="provinceWrap addressItem">
        <el-row>
          <el-col :span="2">所在省</el-col>
          <el-col :span="22">
            <span
              :class="item.isChecked ? 'isActive' : ''"
              @click="selectProvince(index, item.id, item.name)"
              v-for="(item, index) in provinceList"
              :key="item.id"
              >{{ item.name }}</span
            >
          </el-col>
        </el-row>
      </div>
      <div class="cityWrap addressItem">
        <el-row>
          <el-col :span="2">所在市</el-col>
          <el-col :span="22">
            <span
              :class="item.isChecked ? 'isActive' : ''"
              @click="selectCity(index, item.name)"
              v-for="(item, index) in cityList"
              :key="item.id"
              >{{ item.name }}</span
            >
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="listWrap" v-loading="loading">
      <div class="item" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
        <div class="imgWrap">
          <img :src="item.surfacePlot" alt="" />
        </div>
        <div class="content">
          <div class="money">¥ {{ item.budget }}</div>
          <div class="name">{{ item.title }}</div>
          <div class="address"><i style="color: #92acef" class="el-icon-location-outline"></i> {{ item.province + item.city }}</div>
        </div>
      </div>
      <div v-if="!list.length" class="noData">
        <img src="@/assets/images/noData.png" alt="" />
        <p>暂无相关数据</p>
      </div>
    </div>
    <nf-pagination :total="total" :pageSize="15" :currentPage="currentPage" @pageChange="pageChange"></nf-pagination>
  </div>
</template>

<script>
import merge from 'webpack-merge';
import nfPagination from '@/component/pagination/index';

export default {
  data() {
    return {
      // 成果类型
      typeList: [
        { name: '全部', type: '', isChecked: true },
        { name: '技术攻关', type: 0, isChecked: false },
        { name: '专家咨询', type: 1, isChecked: false },
        { name: '专家代理', type: 2, isChecked: false },
        { name: '金融服务', type: 3, isChecked: false },
        { name: '法律服务', type: 4, isChecked: false },
        { name: '专业学会', type: 5, isChecked: false }
      ],
      // 选择预算
      budgetList: [
        { name: '1-5万', isChecked: false },
        { name: '5-10万', isChecked: false },
        { name: '10-50万', isChecked: false },
        { name: '50-100万', isChecked: false },
        { name: '100-500万', isChecked: false },
        { name: '面议', isChecked: true }
      ],
      provinceList: [], // 省列表
      cityList: [
        { province: '海南省', name: '海口市', id: '460100000000', isChecked: false },
        { province: '海南省', name: '三亚市', id: '460200000000', isChecked: false },
        { province: '海南省', name: '三沙市', id: '460300000000', isChecked: false },
        { province: '海南省', name: '儋州市', id: '460400000000', isChecked: false },
        { province: '海南省', name: '省直辖县级行政区划', id: '469000000000', isChecked: false }
      ], // 市列表
      allCity: {},
      // 查询参数
      params: {
        pageNum: 1,
        pageSize: 15,
        budget: '面议',
        city: '',
        province: '海南省',
        type: ''
      },
      total: 0,
      currentPage: Number(this.$route.query.pageNum_two || 1),
      list: [], // 列表
      loading: true
    };
  },
  methods: {
    // 选择类型
    selectType(index, type) {
      this.typeList.forEach(item => {
        item.isChecked = false;
      });
      this.typeList[index].isChecked = true;
      this.params.type = type;
      this.getList();
    },
    // 选择预算
    selectBudget(index, budget) {
      this.budgetList.forEach(item => {
        item.isChecked = false;
      });
      this.budgetList[index].isChecked = true;
      this.params.budget = budget;
      this.getList();
    },
    // 选择省
    selectProvince(index, id, name) {
      this.params.city = '';
      this.provinceList.forEach(item => {
        item.isChecked = false;
      });
      this.provinceList[index].isChecked = true;
      this.cityList = this.allCity[id];
      this.cityList.forEach(item => {
        item.isChecked = false;
      });
      this.params.province = name;
      this.getList();
    },
    // 选择市
    selectCity(index, name) {
      this.cityList.forEach(item => {
        item.isChecked = false;
      });
      this.cityList[index].isChecked = true;
      this.params.city = name;
      this.getList();
    },
    // 获取列表
    async getList() {
      this.loading = true;
      this.params.pageNum = this.$route.query.pageNum_two || 1;
      const api = this.$fetchApi.demandList.api;
      const data = await this.$fetchData.fetchPost(this.params, api, 'json');
      if (data.code === '200') {
        this.total = data.result.total;
        this.list = data.result.rows;
        this.loading = false;
      } else {
        this.loading = false;
        Message.error(data.message);
      }
    },
    // 页码变化
    async pageChange(page) {
      const pageNum_two = page.page_index;
      this.$router.push({
        query: merge(this.$route.query, {
          pageNum_two
        })
      });
      this.getList();
    },
    // 跳转详情
    toDetail(id) {
      this.$router.push({
        path: '/achievementDetail',
        query: { from: '2', id }
      });
    }
  },
  created() {
    this.allCity = require('../../../public/static/JSON/city.json');
    this.provinceList = require('../../../public/static/JSON/province.json');
    this.getList();
  },
  components: { nfPagination }
};
</script>

<style lang="less" scoped>
.hint {
  height: 70px;
  box-sizing: border-box;
  padding: 15px 20px;
  background: #f7f7f7;
  font-size: 16px;
  text-indent: 2em;
  border-radius: 4px;
  color: #757575;
  letter-spacing: 1px;
  margin: 10px 0 20px 0;
}
.addressWrap {
  border: 1px solid #ccc;
  .addressItem {
    font-weight: 900;
    padding: 15px;
    border-bottom: 1px dashed #ccc;
    line-height: 30px;
    span {
      display: inline-block;
      word-wrap: break-word;
      white-space: normal;
      font-weight: 400;
      margin-right: 20px;
      letter-spacing: 1px;
    }
    span.isActive {
      color: #265ae1;
    }
  }
  .addressItem:last-child {
    border-bottom: 0;
  }
}
.listWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 20px;
  .item {
    width: 224px;
    height: 310px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin-bottom: 20px;
    margin-right: 20px;
    .imgWrap {
      height: 200px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .content {
      padding: 10px;
      height: 110px;
      box-sizing: border-box;
      line-height: 30px;
      .money {
        font-size: 24px;
        color: #ff5454;
      }
      .name {
        font-size: 14px;
      }
      .address {
      }
    }
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
}
.noData {
  width: 600px;
  margin: 0 auto;
  img {
    width: 100%;
  }
  p {
    text-align: center;
    font-size: 30px;
    color: #999;
    padding: 30px;
  }
}
</style>
