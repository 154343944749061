<template>
  <div>
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="achievementList">
      <el-tabs v-model="activeName">
        <el-tab-pane label="成果专利库" name="1"><patent-list></patent-list></el-tab-pane>
        <el-tab-pane label="技术需求库" name="2"><demand-list></demand-list></el-tab-pane>
      </el-tabs>
      <div class="issueBtn" @click="$router.push('/download')">我要发布</div>
    </div>
  </div>
</template>

<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import patentList from './patentList';
import demandList from './demandList';

export default {
  name: 'achievementList',
  data() {
    return {
      breadList: [{ name: '热点功能' }, { name: '成果转化' }], // 导航
      activeName: '1'
    };
  },
  components: { nfBreadcrumb, patentList, demandList }
};
</script>

<style lang="less" scoped>
.achievementList {
  .issueBtn {
    position: absolute;
    width: 112px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    background: #265ae1;
    border-radius: 2px;
    top: 12px;
    right: 0;
  }
  position: relative;
  width: 1200px;
  margin: 0 auto;
  /deep/ .el-tabs__item {
    font-size: 18px;
    height: 60px;
    line-height: 60px;
  }
  /deep/ .el-tabs__item.is-active {
    color: #265ae1;
  }
  /deep/ .el-tabs__active-bar {
    height: 3px;
    background: #265ae1;
  }
}
</style>
